@import '../../../../styles/agGridThemeOverrides.css';

.ag-theme-alpine.breakeven-grid .ag-cell {
  border-right: 1px solid #E6E6E6;
}

.ag-theme-alpine.breakeven-grid .ag-row {
  z-index: 0;
}

.ag-theme-alpine.breakeven-grid .ag-cell-label-container {
  width: auto;
}

.ag-theme-alpine.breakeven-grid .ag-header-cell-label {
  text-align: center;
}

.actions-button-cell {
  overflow: visible !important;
}

.ag-theme-alpine.breakeven-grid .ag-row.ag-row-focus {
  z-index: 1;
}

.ag-theme-alpine.breakeven-grid .ag-root {
  overflow: visible !important;
}
