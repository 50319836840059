@import '../../../../styles/agGridThemeOverrides.css';

/* HEADER GROUPING OVERRIDES START */
.ag-theme-alpine.discounts-grid .ag-header,
.ag-theme-alpine.discounts-grid .ag-header .ag-pinned-left-header,
.ag-theme-alpine.discounts-grid .ag-header .ag-header-viewport {
  min-height: 78px !important;
  height: 78px !important;
}

.ag-theme-alpine.discounts-grid .ag-header .ag-pinned-left-header .ag-header-row.ag-header-row-column {
  height: 28px !important;
  top: 50px !important;
}

.ag-theme-alpine.discounts-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-row.ag-header-row-column {
  height: 28px !important;
  top: 50px !important;
}

.ag-theme-alpine.discounts-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-row.ag-header-row-column-group[aria-rowindex="2"] {
  top: 35px !important;
}

.ag-theme-alpine.discounts-grid .ag-header-cell.ag-header-span-height.ag-focus-managed {
  top: -20px !important;
  height: 50px !important;
}