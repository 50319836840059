.ag-theme-alpine {
  background-color: white;
  font-family: Apercu, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #001e33;
}
.ag-theme-alpine .ag-root-wrapper {
  border-radius: 4px;
  border-color: #e6e6e6;
  overflow: visible;
}
.ag-theme-alpine .ag-header {
  background-color: #f7f7f7;
  border-bottom-color: #e6e6e6;
  font-family: Apercu Mono, monospace;
}
.ag-theme-alpine .ag-header-cell .ag-cell-label-container .ag-header-cell-label .ag-header-cell-text {
  white-space: normal;
}

/* HEADER GROUPING OVERRIDES START */
.ag-theme-alpine.discounts-grid .ag-header,
.ag-theme-alpine.discounts-grid .ag-header .ag-pinned-left-header,
.ag-theme-alpine.discounts-grid .ag-header .ag-header-viewport {
  min-height: 78px !important;
  height: 78px !important;
}

/* PINNED COLUMNS */
.ag-theme-alpine .ag-header .ag-pinned-left-header .ag-header-row.ag-header-row-column-group[aria-rowindex="1"]  {
  height: 30px !important;
}
.ag-theme-alpine .ag-header .ag-pinned-left-header .ag-header-row.ag-header-row-column-group[aria-rowindex="2"]  {
  height: 0 !important;
  top: 30px !important;
}
.ag-theme-alpine.discounts-grid .ag-header .ag-pinned-left-header .ag-header-row.ag-header-row-column {
  height: 48px !important;
  top: 30px !important;
}

/* REST OF COLUMNS */
.ag-theme-alpine .ag-header .ag-header-viewport .ag-header-container .ag-header-row.ag-header-row-column-group[aria-rowindex="1"]  {
  height: 30px !important;
}
.ag-theme-alpine .ag-header .ag-header-viewport .ag-header-container .ag-header-row.ag-header-row-column-group[aria-rowindex="2"] {
  height: 18px !important;
  top: 30px !important;
}
.ag-theme-alpine.discounts-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-row.ag-header-row-column {
  height: 48px !important;
  top: 30px !important;
}
.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell, .ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
  border: 0 !important;
}

/* END HEADER GROUPING OVERRIDES */
.ag-theme-alpine .ag-header-cell.tooltip-wrap .ag-cell-label-container .ag-header-cell-label .ag-header-cell-text::after {
  display: inline-block;
  padding: 0;
  width: 10px;
  height: 10px;
  margin: 0 0 0 4px;
  cursor: pointer;
  content: '';
  -webkit-mask: url('../assets/info_inactive.svg');
  mask: url('../assets/info_inactive.svg');
  -webkit-mask-position: center;
  mask-size: contain;
  background-color: #666;
}
.ag-theme-alpine .ag-header-cell.tooltip-wrap:hover .ag-cell-label-container .ag-header-cell-label .ag-header-cell-text::after {
   background-color: #07c;
}
.ag-theme-alpine .ag-row {
  background: white;
  font-weight: 400;
  font-size: 12px;
  color: #001e33;
}
.ag-theme-alpine.discounts-grid .ag-row.ag-row-level-0 {
  font-weight: 700;
}
.bg-color-light {
  background: #e5f4ff;
  color: #07c;
}
.bg-color-dark {
  background: #cfe8fa;
  color: #07c;
}
.ag-theme-alpine .ag-row-odd {
  background: white;
}
.ag-theme-alpine .ag-cell {
  border: 1px solid transparent;
  line-height: 55px;
  padding: 0 16px;
  -webkit-font-smoothing: subpixel-antialiased;
}
.ag-theme-alpine .ag-cell.bold {
  font-weight: 700;
}
.ag-theme-alpine .ag-cell.no-padding {
  padding: 0;
}
.ag-theme-alpine .ag-cell.no-padding-right {
  padding-right: 0;
}
.ag-theme-alpine .ag-cell.rh-center > div {
  height: 100%;
}
.ag-theme-alpine .ag-cell.rh-center > div > div {
  height: 100%;
}
.ag-theme-alpine .ag-cell.background-gray {
  background: #f7f7f7;
}
.ag-theme-alpine .ag-row-selected >.ag-cell.background-gray {
  background: var(--ag-selected-row-background-color);
}
.ag-theme-alpine .ag-row-hover >.ag-cell.background-gray {
  background: var(--ag-row-hover-color);
}
.ag-theme-alpine .ag-cell.override {
  color: #07c;
}
.ag-theme-alpine .ag-cell.no-padding .single-line {
  height: 55px;
}
.ag-theme-alpine .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
  height: 55px;
}
.ag-theme-alpine .ag-cell-wrapper.ag-row-group.ag-row-group-indent-0 .ag-group-value,
.ag-theme-alpine .ag-cell-wrapper.ag-row-group.ag-row-group-indent-0 .ag-group-child-count {
  font-weight: 700;
}
.ag-theme-alpine .ag-cell-wrapper > .ag-group-value > .ag-react-container > div > span.name {
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  height: 42px;
}
.ag-theme-alpine .ag-cell-wrapper > .ag-group-expanded .ag-icon.ag-icon-tree-open,
.ag-theme-alpine .ag-cell-wrapper > .ag-group-contracted .ag-icon.ag-icon-tree-closed {
  width: 16px;
  height: 16px;
  color: #001e33
}
.ag-theme-alpine .ag-cell-wrapper > .ag-group-expanded .ag-icon.ag-icon-tree-open::before,
.ag-theme-alpine .ag-cell-wrapper > .ag-group-contracted .ag-icon.ag-icon-tree-closed::before {
  display: block;
  width: 100%;
  height: 100%;
  content: '';
  -webkit-mask-position: center;
  mask-size: contain;
  background-color: #001e33;
}
.ag-theme-alpine .ag-cell-wrapper > .ag-group-expanded .ag-icon.ag-icon-tree-open::before {
  -webkit-mask: url('../assets/icon_expand.svg');
  mask: url('../assets/icon_expand.svg');
}
.ag-theme-alpine .ag-cell-wrapper > .ag-group-contracted .ag-icon.ag-icon-tree-closed::before {
  -webkit-mask: url('../assets/icon_collapse.svg');
  mask: url('../assets/icon_collapse.svg');
}
.ag-theme-alpine .ag-filter-toolpanel-header,
.ag-theme-alpine .ag-filter-toolpanel-search,
.ag-theme-alpine .ag-status-bar,
.ag-theme-alpine .ag-header-row,
.ag-theme-alpine .ag-panel-title-bar-title,
.ag-theme-alpine .ag-multi-filter-group-title-bar {
  font-family: Apercu Mono, monospace;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 10px;
  color: #666;
}

.ag-theme-alpine .ag-cell > .ag-react-container > .input {
  width: 100%;
  min-width: 0;
}

.ag-theme-alpine .ag-cell > .ag-react-container {
  height: 100%;
}
.ag-theme-alpine .ag-cell.disabled,.ag-header-cell.disabled {
  opacity: 0.4;
}
.ag-theme-alpine .ag-cell.disabled .ag-checkbox-input-wrapper {
  pointer-events: none;
}
