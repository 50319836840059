.ag-theme-alpine.standard-pricing-grid .ag-cell.positive {
  color: #00B377;
}
.ag-theme-alpine.standard-pricing-grid .ag-cell.negative {
  color: #C00;
}
.ag-theme-alpine.standard-pricing-grid .ag-cell.neutral {
  color: #001e33;
}

