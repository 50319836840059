@import '../../../../styles/agGridThemeOverrides.css';

.ag-theme-alpine.discounts-curve-management-grid .ag-cell {
    border-right: 1px solid #E6E6E6;
    line-height: 20px;
}

.ag-theme-alpine.discounts-curve-management-grid .ag-cell:last-child {
    border-right: 0;
}

.ag-theme-alpine.discounts-curve-management-grid .ag-cell-label-container {
    width: auto;
}

.ag-cell-wrap-text {
    word-break: break-word;
}
