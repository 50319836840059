
.cell-vertical-alignment-center {
  display: flex;
  align-items: center;
  width: 100%;
}

div > .ag-react-container {
  width: 100%;
}
