.ui.basic.popup.visible.custom,
.ui.basic.popup.visible.custom.range,
.ui.basic.popup.visible.info-tooltip,
.ui.basic.popup.visible.feature-chart-popup,
.ui.basic.popup.visible.publish-data-popup {
  min-width: 288px !important;
  width: 288px;
  padding: 24px 0 16px;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
  margin-top: 4px;
}
.ui.basic.popup.visible.custom {
  width: 120px;
  min-width: 120px !important;
  padding: 16px 0 24px;
}
.ui.basic.popup.visible.info-tooltip {
  padding: 12px 8px 12px 12px;
  margin: 0 0 0 10px;
}
.ui.basic.popup.visible.info-tooltip.occupancy {
  min-width: 138px !important;
  width: 138px;
}
.ui.basic.popup.visible.info-tooltip.financial {
  min-width: 248px !important;
  width: 248px;
}
.ui.basic.popup.visible.info-tooltip.publish {
  min-width: 296px !important;
  width: 296px;
  padding: 8px;
  margin: 6px 0 0;
}
.ui.basic.popup.visible.info-tooltip.table-info-tooltip {
  min-width: 200px !important;
  width: 200px;
}
.ui.basic.popup.visible.feature-chart-popup {
  min-width: 606px !important;
  width: 606px;
  padding: 20px;
}
.ui.basic.popup.visible.publish-data-popup {
  min-width: 304px !important;
  width: 304px;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  border: 0;
  margin-top: 12px;
}
.ui.basic.popup.visible.publish-data-popup .ui.datepicker {
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
}
.ui.dimmer {
  background-color: rgba(0,15,26,.8);
}
.ui.modal.add-reason-modal {
  width: 368px;
  padding: 30px;
}
.ui.modal.publish-modal {
  width: 810px;
  padding: 40px 24px 40px 40px;
}
.ui.modal.publish-discount-modal {
  width: 600px;
  padding: 0;
}
.ui.modal.discount-curves-modal {
  width: 900px;
  height: 600px;
  overflow: hidden;
  padding: 0;
}

.ui.modal.discount-curves-modal>.actions>.button.align-left {
  float: left;
}

.ui.modal.discount-curves-modal>.actions> span.align-left-msg {
  float: left;
  margin: 13px 8px;
}

.ui.modal.discount-override-modal {
  width: 550px;
  padding: 0;
}
.ui.modal.discount-curve-management-modal {
  width: 932px;
  padding: 0;
}
.ui.modal.add-reason-modal>.close.icon,
.ui.modal.publish-modal>.close.icon {
  top: 30px;
  right: 30px;
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0;
  opacity: 1;
  font-size: 18px;
  color: #1e1e1e;
}
.ui.modal.publish-modal>.close.icon {
  top: 40px;
  right: 40px;
}
.ui.modal.add-reason-modal>.close.icon::before,
.ui.modal.publish-modal>.close.icon::before {
  vertical-align: top;
  width: 24px;
  height: 24px;
  margin: -3px 0 0 -3px;
}
.ui.modal.add-reason-modal>.actions,
.ui.modal.publish-modal>.actions {
  margin: 12px 0 0;
}
.ui.modal.publish-discount-modal>.actions {
  margin: 8px 16px;
}
.ui.modal.add-reason-modal .actions>.button,
.ui.modal.publish-modal .actions>.button {
   width: 120px;
 }
.ui.basic.circular.button.date-picker-trigger {
  float: left;
}
.ui.modal.discount-curves-modal>.actions {
  padding: 20px 40px 30px;
  border-top: 1px solid #e6e6e6;
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.ui.modal.discount-override-modal>.actions {
  padding: 30px 40px 40px;
  border-top: 1px solid #e6e6e6;
  margin: 0;
}
.ui.modal.discount-curve-management-modal>.actions {
  padding: 20px 40px 30px;
}
.ui.modal.discount-curves-modal>.actions::after,
.ui.modal.discount-override-modal>.actions::after {
  display: block;
  content: '';
  clear: both;
}
.ui.button.cancel-confirmation {
  background-color: white;
  color: #07c
}
.ui.button.cancel-confirmation:hover {
  background-color: #e6e6e6;
}
.main-header-landing {
  width: 250px;
  font-size: 3rem !important;
  color: #fff !important;
}
.main-page-button {
  background-color: #fff !important;
  color: #112d99 !important;
}
.ui.checkbox input.hidden {
  z-index: 1;
}
.ui.checkbox.checkbox-top input[type='checkbox'] {
  top: 4px;
}
.ui.checkbox input[type='checkbox'] {
  opacity: unset !important;
}
.ui svg {
  display: inline;
}
.ui.popup.generate-link-popup {
  max-width: 450px;
  width: 450px;
}

.ui.popup.generate-link-popup .ui.action.input {
  display: flex;
}

.ui.popup.generate-link-popup .ui.action.input input {
  font-size: 12px;
  opacity: 0.6;
  cursor: default;
}

.ui.popup.generate-link-popup .ui.action.input button {
  cursor: pointer;
}